import { createAction, props } from '@ngrx/store';
import { Claim } from '@wam/shared';
import { ProfilePictureMapping } from '@wap/profile-picture';

export const selectParent = createAction('[Landing Page] Select Parent');

export const loadClaimsUpstartLanding = createAction('[Upstart Landing Page] Load Claims');

export const loadClaimsSuccess = createAction(
  '[Grant API] Load Claims Success',
  props<{ claims: Claim[] }>(),
);

export const loadClaimsFail = createAction(
  '[Grant API] Load Claims Fail',
  props<{ error: string }>(),
);

export const loadProfilePictures = createAction(
  '[Upstart Landing Page] Load Profile Pictures',
  props<{ studentIds: string[] }>(),
);

export const loadProfilePicturesSuccess = createAction(
  '[Profile Picture API] Load Profile Pictures Success',
  props<{ profilePictures: ProfilePictureMapping[] }>(),
);

export const selectClaim = createAction('[Landing Page] Select Claim', props<{ claim: Claim }>());

export const clearSelectedClaim = createAction(
  '[Student Redesign Layout Component] Clear Selected Claim',
);
